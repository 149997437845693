/* Reinicio de estilos básicos (CSS Reset) */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%; /* Aseguramos que el body ocupe toda la altura */
  min-height: 100vh; /* Esto asegura que el body ocupe al menos la altura completa de la ventana */
  overflow-x: hidden; /* Evita el desplazamiento horizontal */
  background-color: #f6f3e0; /* Fondo de página */
  font-family: 'Poppins', sans-serif; /* Fuente predeterminada */
  color: #000;
  line-height: 1.6;
}

/* Asegurar que las imágenes y los videos sean responsivos */
img,
video {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Estilo básico para botones */
button {
  font: inherit;
  cursor: pointer;
}

/* Mejora de accesibilidad para enlaces */
a {
  text-decoration: none;
  color: inherit;
}

/* Grillas responsivas: Espaciado */
.container {
  margin: 0;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 600px) {
  html {
    font-size: 90%;
  }


  .container {
    padding: 0.5rem;
  }
}
